<template>
  <div>
    {{ displayNumber }}
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      displayNumber: 0,
      interval: false,
    };
  },
//   watch: {
//     number: function () {
//       clearInterval(this.interval);

//       if (this.number == this.displayNumber) {
//         return;
//       }

//       this.interval = window.setInterval(
//         function () {
//           if (this.displayNumber != this.number) {
//             var change = (this.number - this.displayNumber) / 10;

//             change = change >= 0 ? Math.ceil(change) : Math.floor(change);

//             this.displayNumber = this.displayNumber + change;
//           }
//         }.bind(this),
//         20
//       );
//     },
//   },
  mounted() {
    this.displayNumber =  0;
    window.setInterval(
        function () {
          
            var change = (this.number - this.displayNumber) / 8;

            change = change >= 0 ? Math.ceil(change) : Math.floor(change);

            this.displayNumber = this.displayNumber + change;
          
        }.bind(this),
        20
      );
  },
};
</script>

<style>
</style>




