<template>
  <div>
    <section
      class="hero is-fullheight has-background"
    >
      <img
        alt="bg"
        class="hero-background is-transparent"
        src="../assets/bg-house.jpg"
      >
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-spaced has-text-light is-large-title is-size-1-mobile">
            INVOLUTE
          </h1>
          <!-- <h3 class="subtitle has-text-light is-5">
            Veil jouw situatie en ontvang jaarlijks het scherpste aanbod voor al
            je verzekeringen!
          </h3> -->
          <h3 class="subtitle has-text-light is-5">
            Wij draaien het om! Start veilingen en laat verzekeraars bieden op jouw unieke situatie
          </h3>
          <br> 
          <b-button
            v-if="!isAuthenticated"
            tag="router-link"
            to="preregistration/emailregistration"
            class="button is-link block"
            icon-left="play"
          >
            Start aanvraag
          </b-button>
          <b-button
            v-if="isAuthenticated"
            tag="router-link"
            to="/dashboard"
            class="button is-link block"
            icon-left="tachometer-alt"
          >
            Ga naar mijn dashboard
          </b-button>
          <br> <br> 
          <div class="columns">
            <div class="column">
              <b-icon
                icon="stopwatch"
                size="is-large"
                type="is-light"
              />
              <br>
              <br>
              <p class="subtitle has-text-light">
                Eenvoudig
              </p>
              <p>Ontvang jaarlijks automatisch het scherpste aanbod op al jouw verzekeringsproducten</p>
            </div>
            <div class="column">
              <b-icon
                icon="user-edit"
                size="is-large"
                type="is-light"
              /> <br>
              <br>
              <p class="subtitle has-text-light">
                Persoonlijk
              </p>
              <p>
                Jij staat centraal, laat de verzekeraars bieden op jouw situatie
              </p>
            </div>
            <div class="column">
              <b-icon
                icon="magic"
                size="is-large"
                type="is-light"
              /> <br>
              <br>
              <p class="subtitle has-text-light">
                Innovatief
              </p>
              <p>De laatste technologie assisteert jou in het proces</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      ref="infinitescrolltrigger"
      class="section metrics"
    >
      <!-- <h1 class="title has-text-light has-text-centered">
        Jouw voorgangers
      </h1> -->
      <!-- <h2 class="subtitle has-text-light has-text-centered">
        Bespaar eenvoudig in 3 stappen:
      </h2> -->
      <nav
        v-if="showMetrics"
        class="level has-text-light"
      >
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">
              # aangesloten klanten
            </p>
            <p
              v-if="metrics"
              class="title has-text-light"
            >
              <Animatednumber
                
                :number="metrics.users"
              />
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">
              Gemiddelde jaarlijks besparing (€):
            </p>
            <p class="title has-text-light">
              <Animatednumber :number="951" />
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">
              # veilingen:
            </p>
            <p
              v-if="metrics"
              class="title has-text-light"
            >
              <Animatednumber :number="metrics.closedAuctions" />
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div class="">
            <p class="heading">
              # aangesloten verzekeraars
            </p>
            <p
              v-if="metrics"
              class="title has-text-light"
            >
              <Animatednumber :number="metrics.insurers" />
            </p>
          </div>
        </div>
      </nav>
    </section>
    <section
      id="features"
      class="section has-company-bg"
    >
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column has-text-centered">
            <b-icon
              icon="home"
              size="is-large"
            /> 
            <h3 class="is-size-3">
              Deel je situatie
            </h3>
            <p class="has-text-white paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
            </p>
          </div>
          <div class="column" />
          <!-- <div class="column has-text-centered">
            <b-icon
              icon="home"
              size="is-large"
            />
          </div> -->
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column" />
          <!-- <div class="column has-text-centered">
            <b-icon
              icon="gavel"
              size="is-large"
            />
          </div> -->
          <div class="column has-text-centered">
            <b-icon
              icon="gavel"
              size="is-large"
            />
            <h3 class="is-size-3">
              Start veilingen
            </h3>
            <p class="has-text-white paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column has-text-centered">
            <b-icon
              icon="chart-line"
              size="is-large"
            />
            <h3 class="is-size-3 ">
              Bespaar elk jaar
            </h3>
            <p class="has-text-white paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
            </p>
          </div>
          <div class="column" />
          <!-- <div class="column has-text-centered">
            <b-icon
              icon="chart-line"
              size="is-large"
            />
          </div> -->
        </div>
      </div>
    </section>
    <!-- <section
      class="section has-text-light"
    >
      <h1 class="title has-text-light has-text-centered is-underlined">
        Hoe werkt het?
      </h1>

      <div class="columns is-vcentered has-text-centered">
        <div class="column">
          <b-icon
            size="is-large"
            icon="home"
            type="is-light"
          />
        </div><div class="column">
          <div class="">
            Deel je situatie
          </div>
          Laat je transacties analyseren
        </div>
      </div>

      <div class="columns is-vcentered has-text-centered">
        <div class="column">
          Start je veilingen
        </div>
        <div class="column">
          <b-icon
            size="is-large"
            icon="home"
          />
        </div>
      </div>

      <div class="columns is-vcentered has-text-centered">
        <div class="column">
          <b-icon
            size="is-large"
            icon="home"
          />
        </div><div class="column">
          Monitor je verzekeringen
        </div>
      </div>
    </section> -->
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import Animatednumber from "../components/shared/animatedNumber.vue"

export default {
  name: "Home",
  components: {
    Animatednumber,
  },
  data() {
    return {
      scrolled: false,
      showMetrics: false
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "metrics"]),

  },

  mounted () {
    this.getMetrics();
    this.scrollTrigger();
     },

  methods: {
    ...mapActions(["getMetrics"]),
          scrollTrigger() {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if(entry.intersectionRatio > 0) {
              this.showMetrics = true;
            }
          });
        });
        observer.observe(this.$refs.infinitescrolltrigger);
      },



    // handleScroll () {
    //   this.scrolled = true
    // },
    // test(event) {
    //   if(event == 'scroll')
    //   {
    //   console.log('hoihioh')
      
    // }}

  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";



  // .hero {
  //   height: 80vh
  // }


// .hero.has-background {
//  background-image: url("../assets/bg-house.jpg");
//         background-position: center center;
//         background-repeat:  no-repeat;
//         background-attachment: fixed;
//         background-size:  cover;
//         background-color: rgba(white, 0.1)
// }

// .box {
//   background-color: rgba($light, 0.1)
// }

.hero.has-background {
  // position: relative;
  // overflow: hidden;
  background-color: $primary;
  // height: 80vh; 
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  // border-bottom: 0.2rem rgba($color: $link, $alpha: 1.0) solid;
}
.hero-background.is-transparent {
  opacity: 0.6;
}

.is-large-title {
  font-size: 5rem;
}

hr {
  color: $link
}

.section.metrics {
  border-top: 0.1rem rgba($color: $link, $alpha: 1.0) solid;
  //  border-bottom: 0.1rem rgba($color: $link, $alpha: 1.0) solid;
}


// .background {
//   background-image: url("../assets/bg-house.jpg");
//   background-position: center;
//   background-size: cover;
// }

.before-enter {
opacity: 0;
transform: translateX(100px);
transition: all 300ms ease-out
}

.enter {
opacity: 1;
transform: translateX(0px);
}

</style>